<div class="info-data" *ngIf="vendorDetails?.phone_number">
    <div class="icon-card">
        <img src="./assets/images/icons/ic_phone.svg" alt="">
    </div>
    <div>
        <label>{{'general.Mobile Number' | translate}}</label>
        <div class="always-ltr">+(966) {{vendorDetails?.phone_number}}</div>
    </div>
</div>
<div class="info-data email" *ngIf="vendorDetails?.email">
    <div class="icon-card">
        <img src="./assets/images/icons/ic_email.svg" alt="">
    </div>
    <div>
        <label>{{'login.Email' | translate}}</label>
        <a href="mailto:{{vendorDetails?.email}}">{{vendorDetails?.email}}</a>
    </div>
</div>
<div class="info-data" *ngIf="vendorDetails?.website">
    <div class="icon-card">
        <img src="./assets/images/icons/ic_website.png" alt="">
    </div>
    <div>
        <label>{{'vendors.Website' | translate}}</label>
        <a [href]="vendorDetails.website" target="_blank">{{vendorDetails?.website}}</a>
    </div>
</div>
<p class="font-14 fontRegular text-color mb-0" *ngIf="vendorDetails?.social_media_links.facebook_link 
|| vendorDetails?.social_media_links.instagram_link 
|| vendorDetails?.social_media_links.x_link
|| vendorDetails?.social_media_links.whatsapp
|| vendorDetails?.social_media_links.snapchat
|| vendorDetails?.social_media_links.tiktok">
    {{"vendorAdminProfile.Social media channels"|translate}}
</p>

<div class="info-data" *ngIf="vendorDetails?.social_media_links.facebook_link">
    <div class="icon-card social-icon-card">
        <img src="/assets/images/icons/facebook.svg" alt="">
    </div>
    <div>
        <label>{{'vendors.Facebook' | translate}}</label>
        <a target="_blank"
            [href]="vendorDetails?.social_media_links.facebook_link">{{vendorDetails?.social_media_links.facebook_link}}</a>
    </div>
</div>
<div class="info-data" *ngIf="vendorDetails?.social_media_links.instagram_link">
    <div class="icon-card social-icon-card">
        <img src="/assets/images/icons/instagram.png" alt="">
    </div>
    <div>
        <label>{{'vendors.Instagram' | translate}}</label>
        <a target="_blank"
            [href]="vendorDetails?.social_media_links.instagram_link">{{vendorDetails?.social_media_links.instagram_link}}</a>
    </div>
</div>
<div class="info-data" *ngIf="vendorDetails?.social_media_links.x_link">
    <div class="icon-card social-icon-card">
        <img src="/assets/images/icons/twitter.svg" alt="">
    </div>
    <div>
        <label>{{'vendors.X' | translate}}</label>
        <a target="_blank"
            [href]="vendorDetails?.social_media_links.x_link">{{vendorDetails?.social_media_links.x_link}}</a>
    </div>
</div>
<div class="info-data" *ngIf="vendorDetails?.social_media_links.whatsapp">
    <div class="icon-card social-icon-card">
        <img src="/assets/images/icons/whatsapp_logo.png" alt="">
    </div>
    <div>
        <label>{{'vendors.whatsapp' | translate}}</label>
        <a target="_blank"
        href="https://api.whatsapp.com/send?phone=966{{vendorDetails?.social_media_links.whatsapp}}">{{vendorDetails?.social_media_links.whatsapp}}</a>
    </div>
</div>
<div class="info-data" *ngIf="vendorDetails?.social_media_links.snapchat">
    <div class="icon-card social-icon-card">
        <img src="/assets/images/icons/snapchat-logo.svg" alt="">
    </div>
    <div>
        <label>{{'vendors.snapchat' | translate}}</label>
        <a target="_blank"
            [href]="vendorDetails?.social_media_links.snapchat">{{vendorDetails?.social_media_links.snapchat}}</a>
    </div>
</div>
<div class="info-data" *ngIf="vendorDetails?.social_media_links.tiktok">
    <div class="icon-card social-icon-card">
        <img src="/assets/images/icons/tiktok-app-icon.png" alt="">
    </div>
    <div>
        <label>{{'vendors.tiktok' | translate}}</label>
        <a target="_blank"
            [href]="vendorDetails?.social_media_links.tiktok">{{vendorDetails?.social_media_links.tiktok}}</a>
    </div>
</div>