import { Component } from '@angular/core';
import { MetricsService } from './metrics.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DirtyComponent } from '../../helpers/CanDeactivate/dirty-component';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styles: ``
})
export class MetricsComponent extends DirtyComponent {

  constructor(private formBuilder: FormBuilder,
    private service: MetricsService,
    translate: TranslateService
  ) {
    super(translate)
  }

  ngOnInit(): void {
    this.buildForm();
    this.addRange();
    this.addQuestion();
    this.addAnswer(0);
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      name_ar: [],
      name_en: [],
      image: [],
      expected_time: [],
      is_active: [],
      text_en: [],
      text_ar: [],
      ranges: this.formBuilder.array([]),
      questions: this.formBuilder.array([]),
    });
  }

  ranges(): FormArray {
    return this.formGroup.get("ranges") as FormArray
  }

  newRange(): FormGroup {
    return this.formBuilder.group({
      min: [],
      max: [],
      name_ar: [],
      name_en: [],
      description_ar: [],
      description_en: []
    })
  }

  addRange() {
    this.ranges().push(this.newRange());
  }

  removeRange(index: number) {
    this.ranges().removeAt(index);
    // if (!this.ranges.length) {
    //   this.addRange();
    // }
  }

  questions(): FormArray {
    return this.formGroup.get("questions") as FormArray
  }

  newQuestion(): FormGroup {
    return this.formBuilder.group({
      name_en: [],
      name_ar: [],
      description_ar: [],
      description_en: [],
      title_top_en: [],
      title_bottom_en: [],
      title_top_ar: [],
      title_bottom_ar: [],
      degree: [],
      type: ['answer'],
      answers: this.formBuilder.array([]),
    })
  }

  addQuestion() {
    this.questions().push(this.newQuestion());
  }

  removeQuestion(index: number) {
    this.questions().removeAt(index);
    // if (!this.questions.length) {
    //   this.addQuestion();
    // }
  }


  questionAnswers(questionIndex: number): FormArray {
    return this.questions().at(questionIndex).get("answers") as FormArray
  }

  newAnswer(): FormGroup {
    return this.formBuilder.group({
      name_en: [],
      name_ar: [],
      degree: []
    })
  }

  addAnswer(questionIndex: number) {
    this.questionAnswers(questionIndex).push(this.newAnswer());
  }

  removeQuestionAnswer(questionIndex: number, index: number) {
    this.questionAnswers(questionIndex).removeAt(index);
    // if (!this.questionAnswers.length) {
    //   this.addAnswer(0);
    // }
  }

  saveMetric() {
    this.service.metrics(this.formGroup.value).subscribe((res) => {
      if (res) {
        Swal.fire({
          title: this.translate.instant('swalMsg.Success'),
          text: this.translate.instant('swalMsg.savedsuccessfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
        window.location.reload();
      }
    },
      (error: any) => {
        Swal.fire({
          title: this.translate.instant('swalMsg.Failed'),
          icon: 'error',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
      });
  }
}
