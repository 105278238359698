<nav class="head-nav d-flex">
    <h2>Metrics</h2>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="/admin/profile">{{ "general.Home" | translate }}</a>
        </li>
        <!-- <li class="breadcrumb-item">{{ "roles.Wala-X Employees" | translate }}</li> -->
        <li class="breadcrumb-item">Metrics</li>
    </ol>
</nav>


<div class="page metrics">
    <form [formGroup]="formGroup">
        <div class="card">
            <div class="title">
                Metric
            </div>
            <div class="row align-items-center">
                <div class="col-md-3">
                    <div class="fields-group mb-3">
                        <mat-form-field class="arabic-form-field">
                            <mat-label>Metric name in Arabic</mat-label>
                            <input matInput formControlName="name_ar" placeholder="مقياس">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>Metric name in English</mat-label>
                            <input matInput formControlName="name_en" placeholder="Metric Example">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>Image</mat-label>
                            <input matInput formControlName="image" placeholder="life_assessment.png">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>Expected Time</mat-label>
                            <input matInput formControlName="expected_time">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="fields-group mb-3">
                        <mat-slide-toggle formControlName="is_active">
                            Status
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="fields-group mb-3">
                        <mat-form-field class="arabic-form-field">
                            <mat-label>Text in Arabic</mat-label>
                            <input matInput formControlName="text_ar" placeholder="هذا مثال لمقياس باللغة العربية">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>Text in English</mat-label>
                            <input matInput formControlName="text_en" placeholder="This is an example metric in English">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="ranges">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <div class="title mb-0">
                        Ranges
                    </div>
                    <button mat-button class="btn-primary" (click)="addRange()">Add More Ranges</button>
                </div>
                <div formArrayName="ranges">
                    <div *ngFor="let range of ranges().controls; let rangeIndex = index" [formGroupName]="rangeIndex">
                        <p>Range No.{{rangeIndex+1}}</p>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="fields-group mb-3">
                                    <mat-form-field class="arabic-form-field">
                                        <mat-label>Range name in Arabic</mat-label>
                                        <input matInput formControlName="name_ar">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="fields-group mb-3">
                                    <mat-form-field>
                                        <mat-label>Range name in English</mat-label>
                                        <input matInput formControlName="name_en">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="fields-group mb-3">
                                    <mat-form-field>
                                        <mat-label>min</mat-label>
                                        <input matInput formControlName="min">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="fields-group mb-3">
                                    <mat-form-field>
                                        <mat-label>max</mat-label>
                                        <input matInput formControlName="max">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="fields-group mb-3">
                                    <mat-form-field class="arabic-form-field">
                                        <mat-label>Description in Arabic</mat-label>
                                        <input matInput formControlName="description_ar">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="fields-group mb-3">
                                    <mat-form-field>
                                        <mat-label>Description in English</mat-label>
                                        <input matInput formControlName="description_en">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4" *ngIf="ranges().controls.length > 1">
                            <div class="col-md-6">
                                <button mat-button class="btn-danger" (click)="removeRange(rangeIndex)">
                                    Remove Range {{rangeIndex+1}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="questions">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <div class="title mb-0">
                        Questions
                    </div>
                    <button mat-button class="btn-primary" (click)="addQuestion()">Add More Question</button>
                </div>
                <div formArrayName="questions">
                    <div *ngFor="let question of questions().controls; let questionIndex = index">
                        <div [formGroupName]="questionIndex">
                            Question No.{{questionIndex+1}}
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="fields-group mb-3">
                                        <mat-form-field class="arabic-form-field">
                                            <mat-label>Question in Arabic</mat-label>
                                            <input matInput formControlName="name_ar">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="fields-group mb-3">
                                        <mat-form-field>
                                            <mat-label>Question in English</mat-label>
                                            <input matInput formControlName="name_en">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="fields-group mb-3">
                                        <mat-form-field>
                                            <mat-label>Degree</mat-label>
                                            <input matInput formControlName="degree">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="fields-group mb-3">
                                        <mat-form-field class="arabic-form-field">
                                            <mat-label>Description in Arabic</mat-label>
                                            <input matInput formControlName="description_ar">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="fields-group mb-3">
                                        <mat-form-field>
                                            <mat-label>Description in English</mat-label>
                                            <input matInput formControlName="description_en">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="fields-group mb-3">
                                        <mat-form-field>
                                            <mat-label>title top english</mat-label>
                                            <input matInput formControlName="title_top_en">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="fields-group mb-3">
                                        <mat-form-field class="arabic-form-field">
                                            <mat-label>title top arabic</mat-label>
                                            <input matInput formControlName="title_top_ar">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="fields-group mb-3">
                                        <mat-form-field>
                                            <mat-label>title bottom english</mat-label>
                                            <input matInput formControlName="title_bottom_en">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="fields-group mb-3">
                                        <mat-form-field class="arabic-form-field">
                                            <mat-label>title bottom arabic</mat-label>
                                            <input matInput formControlName="title_bottom_ar">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mb-4">
                                <div class="title mb-0">
                                    Answers for Question No.{{questionIndex+1 }}
                                </div>
                                <button mat-button class="btn-primary" (click)="addAnswer(questionIndex)">Add More
                                    Answers</button>
                            </div>
                            <div formArrayName="answers">
                                <div class="row align-items-baseline"
                                    *ngFor="let answer of questionAnswers(questionIndex).controls; let answerIndex=index"
                                    [formGroupName]="answerIndex">
                                    <div class="col-md-4">
                                        <div class="fields-group mb-3">
                                            <mat-form-field class="arabic-form-field">
                                                <mat-label>Answer in Arabic</mat-label>
                                                <input matInput formControlName="name_ar">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="fields-group mb-3">
                                            <mat-form-field>
                                                <mat-label>Answer in English</mat-label>
                                                <input matInput formControlName="name_en">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="fields-group mb-3">
                                            <mat-form-field>
                                                <mat-label>Degree</mat-label>
                                                <input matInput formControlName="degree">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-1" *ngIf="questionAnswers(questionIndex).controls.length > 1">
                                        <button mat-icon-button class="btn-danger"
                                            (click)="removeQuestionAnswer(questionIndex, answerIndex)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18"
                                                viewBox="0 0 14 18">
                                                <path id="Path_19036" data-name="Path 19036"
                                                    d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V9a2.006,2.006,0,0,0-2-2H8A2.006,2.006,0,0,0,6,9ZM18,4H15.5l-.71-.71a1.009,1.009,0,0,0-.7-.29H9.91a1.009,1.009,0,0,0-.7.29L8.5,4H6A1,1,0,0,0,6,6H18a1,1,0,0,0,0-2Z"
                                                    transform="translate(-5 -3)" fill="#dc3545" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4" *ngIf="questions().controls.length > 1">
                            <div class="col-md-3">
                                <button mat-button class="btn-danger" (click)="removeQuestion(questionIndex)">
                                    Remove Question {{questionIndex+1}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button mat-button class="btn-primary" (click)="saveMetric()">Save</button>
            </div>
        </div>
    </form>
</div>