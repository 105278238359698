import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { MyProfileComponent } from './features/my-profile/my-profile.component';
import { AuthGuard } from './helpers/auth.guard';
import { ErrorComponent } from './features/pages/error/error.component';
import { NotAuthorizedComponent } from './features/pages/not-authorized/not-authorized.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { VerifyAccountComponent } from './features/auth/components/verify-account/verify-account.component';
import { CompanyAdminProfileComponent } from './features/Company-Admin/company-admin-profile/company-admin-profile.component';
import { SendPointsComponent } from './features/Company-Admin/send-points/send-points.component';
import { PaymentConfirmationPageComponent } from './shared/payment-confirmation-page/payment-confirmation-page.component';
import { VendorAdminProfileComponent } from './features/Vendor-Admin/vendor-admin-profile/vendor-admin-profile.component';
import { VerifyChangesComponent } from './features/auth/components/verify-changes/verify-changes.component';
import { MetricsComponent } from './features/metrics/metrics.component';

const routes: Routes = [
  { path: '', component: BasicLayoutComponent, loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '', component: AuthLayoutComponent, children: [
      { path: 'verify-account/:token/:phone', component: VerifyAccountComponent },
      { path: 'verify-changes/:token', component: VerifyChangesComponent },
    ]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: (localStorage.getItem('baseRoute') || 'profile'), pathMatch: 'full' },
      { path: "profile", component: MyProfileComponent },
      { path: 'dashboard', loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'manageUser/roles', loadChildren: () => import('./features/roles/roles.module').then(m => m.RolesModule) },
      { path: 'manageUser/users', loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule) },
      { path: 'employees', loadChildren: () => import('./features/companies-users/companies-users.module').then(m => m.CompaniesUsersModule) },
      { path: "profile", component: MyProfileComponent },
      { path: 'send-points', component: SendPointsComponent },
      { path: 'companies', loadChildren: () => import('./features/companies/companies.module').then(m => m.CompaniesModule) },
      { path: 'categories', loadChildren: () => import('./features/categories/categories.module').then(m => m.CategoriesModule) },
      { path: 'vendors', loadChildren: () => import('./features/vendors/vendors.module').then(m => m.VendorsModule) },
      { path: 'offers', loadChildren: () => import('./features/offers/offers.module').then(m => m.OffersModule) },
      { path: 'packages', loadChildren: () => import('./features/packages/packages.module').then(m => m.PackagesModule) },
      { path: 'notifications', loadChildren: () => import('./features/notifications/notifications.module').then(m => m.NotificationsModule) },
      { path: 'newsletters', loadChildren: () => import('./features/newsletters/newsletters.module').then(m => m.NewslettersModule) },
      { path: 'support-tickets', loadChildren: () => import('./features/support-tickets/support-tickets.module').then(m => m.SupportTicketsModule) },
      { path: 'vendor-support-tickets', loadChildren: () => import('./features/Vendor-Admin/vendor-support-ticket/vendor-support-ticket.module').then(m => m.VendorSupportTicketModule) },
      { path: 'surveys', loadChildren: () => import('./features/surveys/surveys.module').then(m => m.SurveysModule) },
      { path: 'settings', loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule) },
      { path: 'banners', loadChildren: () => import('./features/banners/banners.module').then(m => m.BannersModule) },

      // Company Admin
      { path: 'company-departments', loadChildren: () => import('./features/Company-Admin/departments/departments.module').then(m => m.DepartmentsModule) },
      { path: 'company-rewarded-points', loadChildren: () => import('./features/Company-Admin/company-rewarded-points/company-rewarded-points.module').then(m => m.CompanyRewardedPointsModule) },
      { path: 'company-withdrawal-requests', loadChildren: () => import('./features/Company-Admin/company-withdrawal-requests/company-withdrawal-requests.module').then(m => m.CompanyWithdrawalRequestsModule) },
      { path: 'company-offer-requests', loadChildren: () => import('./features/Company-Admin/company-offer-requests/company-offer-requests.module').then(m => m.CompanyOfferRequestsModule) },
      { path: 'company-admin-profile', component: CompanyAdminProfileComponent },
      { path: 'news', loadChildren: () => import('./features/Company-Admin/news/news.module').then(m => m.NewsModule) },
      { path: 'company-offers', loadChildren: () => import('./features/Company-Admin/company-offers/company-offers.module').then(m => m.CompanyOffersModule) },
      { path: 'company-surveys', loadChildren: () => import('./features/Company-Admin/company-survey/company-survey.module').then(m => m.CompanySurveyModule) },
      { path: 'company-support-tickets', loadChildren: () => import('./features/Company-Admin/company-support-ticket/company-support-ticket.module').then(m => m.CompanySupportTicketModule) },
      { path: 'company-notifications', loadChildren: () => import('./features/Company-Admin/company-notifications/company-notifications.module').then(m => m.CompanyNotificationsModule) },

      // Vendor Admin
      { path: 'vendor-admin-profile', component: VendorAdminProfileComponent },
      { path: 'branches', loadChildren: () => import('./features/Vendor-Admin/branches/branches.module').then(m => m.BranchesModule) },
      { path: 'claimed-offers-and-points', loadChildren: () => import('./features/Vendor-Admin/claimed-offers-and-points/claimed-offers-and-points.module').then(m => m.ClaimedOffersAndPointsModule) },
      { path: 'vendor-offers', loadChildren: () => import('./features/Vendor-Admin/vendor-offers/vendor-offers.module').then(m => m.VendorOffersModule) },
      { path: 'vendor-offer-requests', loadChildren: () => import('./features/Vendor-Admin/vendor-offer-requests/vendor-offer-requests.module').then(m => m.VendorOfferRequestsModule) },
      { path: 'vendor-withdrawal-requests', loadChildren: () => import('./features/Vendor-Admin/vendor-withdrawal-requests/vendor-withdrawal-requests.module').then(m => m.VendorWithdrawalRequestsModule) },
      { path: 'consultants', loadChildren: () => import('./features/consultants/consultants.module').then(m => m.ConsultantsModule) },
      { path: 'consultation-sessions', loadChildren: () => import('./features/consultaion-sessions/consultaion-sessions.module').then(m => m.ConsultaionSessionsModule) },
      { path: 'hr-department', loadChildren: () => import('./features/Company-Admin/hr-department/hr-department.module').then(m => m.HrDepartmentModule)},
      { path: "paymentconfirmation", component: PaymentConfirmationPageComponent },
      { path: 'attendance-list', loadChildren: () => import('./features/HR-Manager/attendance-list/attendance-list.module').then(m => m.AttendanceListModule) },
      { path: 'employee-requests', loadChildren: () => import('./features/HR-Manager/employee-requests/employee-requests.module').then(m => m.RegisterationRequestsModule) },
      { path: 'employee-registered-devices', loadChildren: () => import('./features/HR-Manager/employee-registered-devices/employee-registered-devices.module').then(m => m.EmployeeRegisteredDevicesModule) },
      { path: "metrics", component: MetricsComponent },
      { path: 'notAuthorized', component: NotAuthorizedComponent },
      { path: '**', component: ErrorComponent },
    ],
  },
  { path: '**', component: ErrorComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
