import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { ApiServiceService } from '../../services/api-service.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  refreshList = new BehaviorSubject<boolean>(false);
  closeDetails = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiServiceService) { }

  metrics(data: any) {
    return this.apiService.post(`${environment.apiUrl}/metrics`, data)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }
}